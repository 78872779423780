













































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';

@Component({
    components: {}
})
export default class PaywallOverlay extends Vue {
    // Member
        
    // Properties
    @Prop({ required: true, type: Boolean })
    public hasFeature!: boolean;

    @Prop({ required: false, default: false, type: Boolean })
    public requiredLogin!: boolean;

    @Prop({ required: false, type: Number, default: 10 })
    public blur!: number;

    @Prop({ required: false, type: Number, default: 1 })
    public zIndex!: number;

    // Lifecycle Methods:

    // Methods:
}
