





























































































































































































































































































































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';
import { Brand, BrandIndexItem, BrandQueryResultItem, BrandQueryResultItemScore, MasterDataItem } from '@/api/braendz';
import { dummyBrand } from '@/models/DummyBrand'
import { AccountInfo } from "@azure/msal-browser";

import PaywallOverlay from '@/components/Features/PaywallOverlay.vue';
import BrandDetailsPopup from '@/components/Brands/BrandDetailsPopup.vue';
import SaveBrandBookmarkPopup from '@/components/BrandBookmarks/SaveBrandBookmarkPopup.vue';
import ConfirmationPopup from '@/components/Popups/ConfirmationPopup.vue';
import TruncateTooltip from '@/components/TruncateTooltip.vue';
import ShowAllExpander from '@/components/ShowAllExpander.vue';
import { brandStateCategoryColors, brandStateCategoryIcons } from '@/models/BrandStateCategories';
import RadialBar from '@/components/Statistics/RadialBar.vue';
import BrandSimilaritiesBarChart from '../Statistics/BrandSimilaritiesBarChart.vue';
import Label from '@/components/Label.vue';

@Component({
    components: {
        PaywallOverlay,
        BrandDetailsPopup,
        SaveBrandBookmarkPopup,
        ConfirmationPopup,
        TruncateTooltip,
        ShowAllExpander,
        RadialBar,
        BrandSimilaritiesBarChart,
        Label
    }
})
export default class VerticalBrandTile extends Vue {
    // public isDummyLogo: boolean | null = null;
    public saveBookmarkPopupVisible = false;
    public deleteBookmarkConfirmationPopupVisible = false;

    // Component Properties:
    @Prop({ required: true })
    public brand!: BrandQueryResultItem | BrandIndexItem | Brand | null | undefined;

    @Prop({ required: false })
    public loading?: boolean;

    @Prop({ required: false })
    public maxWidth?: number;

    @Prop({ required: false })
    public maxHeight?: number;

    @Prop({ required: false, default: '100%' })
    public width!: string;

    @Prop({ required: false })
    public height?: string;

    @Prop({ required: false })
    public canAddToBookmarks?: boolean;

    @Prop({ required: false })
    public canRemoveFromBookmarks?: boolean;

    @Prop({ required: false, default: true, type: Boolean })
    public showCommon!: boolean;

    @Prop({ required: false, default: true, type: Boolean })
    public showDates!: boolean;

    @Prop({ required: false, default: true, type: Boolean })
    public showOwner!: boolean;

    @Prop({ required: false, default: true, type: Boolean })
    public showNiceClasses!: boolean;

    @Prop({ required: false, default: false, type: Boolean })
    public showGoodsServices!: boolean;

    @Prop({ required: false, default: true, type: Boolean })
    public showViennaClasses!: boolean;

    // Getter
    public get userAccount(): AccountInfo | null {
        return this.$store.state.userAccount;
    }

    public get brandData(): Brand | BrandIndexItem {
        if(!this.brand) {
            return dummyBrand;
        }
        else if("indexItem" in this.brand) {
            return this.brand.indexItem ?? dummyBrand;
        }
        else if("name" in this.brand) {
            return this.brand;
        }
        return dummyBrand;
    }

    public get scoreData(): BrandQueryResultItemScore | null {
        if(this.brand) {
            if("score" in this.brand) {
                return this.brand.score ?? null;
            }
        }
        return null;
    }

    public get brandId(): string | null {
        if(!this.brand) {
            return null;
        }

        if("brandId" in this.brand && this.brand.brandId) {
            return this.brand.brandId;
        } 
        else if("id" in this.brand && this.brand.id) {
            return this.brand.id;
        }
        else if("indexItem" in this.brand && this.brand.indexItem?.brandId) {
            return this.brand.indexItem.brandId;
        }

        return null;
    }

    public get brandStateCategory(): MasterDataItem | null {
        if(!this.brand) {
            return null;
        }

        if("indexItem" in this.brand && this.brand.indexItem?.brandStateCategory) {
            return this.brand.indexItem.brandStateCategory;
        }
        else if("brandStateCategory" in this.brand && this.brand.brandStateCategory) {
            return this.brand.brandStateCategory;
        }

        return null;
    }

    public get brandStateCategoryColor(): string | undefined {
        return brandStateCategoryColors.find(i => i.key === this.brandStateCategory?.key)?.color;
    }

    public get brandStateCategoryIcon(): string | undefined {
        return brandStateCategoryIcons.find(i => i.key === this.brandStateCategory?.key)?.icon;
    }

    public get hidden() {
        return !this.brand;
    }

    public get fullBrandLogoUrl(): string | null {
        if(this.hidden) {
            return null;
        }
        return this.$braendz.getBrandLogoUrl(this.brandData);
    }

    // Methods:
    public addToBookmarks(): void {
        if(this.brandId) {
            this.saveBookmarkPopupVisible = true;
        }
    }

    public removeFromBookmarks(): void {
        this.$emit('removeFromBookmarks', this.brandId);
    }
}
