var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$slots.default && _vm.hasFeature
    ? _c(
        "span",
        _vm._b({ staticClass: "relative" }, "span", _vm.$attrs, false),
        [_vm._t("default")],
        2
      )
    : _c(
        "div",
        _vm._b(
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.hasFeature,
                expression: "!hasFeature",
              },
            ],
            class: {
              relative: !!_vm.$slots.default,
              "absolute-fill": !_vm.$slots.default,
            },
            staticStyle: { cursor: "default" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
              },
            },
          },
          "div",
          _vm.$attrs,
          false
        ),
        [
          _vm._t("default"),
          _c("div", {
            class: ["absolute-fill", "blur-" + _vm.blur],
            style: { "z-index": _vm.zIndex },
          }),
          _c(
            "v-overlay",
            {
              attrs: {
                absolute: "",
                value: !_vm.hasFeature,
                opacity: "0.0",
                dark: false,
                light: true,
                "z-index": _vm.zIndex,
              },
            },
            [
              _vm.requiredLogin
                ? [
                    _c(
                      "v-btn",
                      {
                        attrs: { plain: "" },
                        on: {
                          click: function ($event) {
                            return _vm.$auth.signIn()
                          },
                        },
                      },
                      [
                        _c("v-icon", { attrs: { left: "" } }, [
                          _vm._v("fa-light fa-right-to-bracket"),
                        ]),
                        _vm._v(
                          " " + _vm._s(_vm.$t("paywallOverlay.loginText")) + " "
                        ),
                      ],
                      1
                    ),
                  ]
                : [
                    _c(
                      "v-btn",
                      { attrs: { plain: "", to: { name: "Pricing" } } },
                      [
                        _c("v-icon", { attrs: { left: "" } }, [
                          _vm._v("fa-light fa-cart-shopping"),
                        ]),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("paywallOverlay.subscribeText")) +
                            " "
                        ),
                      ],
                      1
                    ),
                  ],
            ],
            2
          ),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }