var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "d-flex": true,
        "flex-column": _vm.location === "top",
        "flex-column-reverse": _vm.location === "bottom",
        "justify-start": _vm.justify === "start",
        "justify-end": _vm.justify === "end",
        "justify-center": _vm.justify === "center",
      },
    },
    [
      _c(
        "span",
        {
          class: {
            "d-block caption": true,
            "text-left": _vm.align === "left",
            "text-right": _vm.align === "right",
            "text-center": _vm.align === "center",
          },
          style: {
            "margin-top": !_vm.offsetY
              ? 0
              : _vm.location === "top"
              ? _vm.offsetY + "px"
              : 0 - _vm.offsetY + "px",
          },
        },
        [_vm._v(_vm._s(_vm.text))]
      ),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }