






























import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';
import { BusyList, BusyObject } from '@/models/Busy';
import { BrandBookmark, BrandBookmarkContainer, BrandBookmarkContainersService, BrandBookmarksService } from '@/api/braendz';

import Popup from '@/components/Popups/Popup.vue';

@Component({
    components: {
        Popup
    },
    model: {
        prop: "model",
        event: "visibilityChanged"
    }
})
export default class SaveBrandBookmarkPopup extends Vue {
    // Member
    public visibility = false;

    public brandBookmarkContainers = new BusyList<BrandBookmarkContainer>();
    public brandBookmarkContainer = new BusyObject<BrandBookmarkContainer>();
    public brandBookmark = new BusyObject<BrandBookmark>();

    // Properties
    public valid = false;

    // Properties
    @Prop({ required: false })
    public model?: boolean;

    @Prop({ required: true })
    public brandId!: string;

    public containerName: string | null = null;

    // Getter & Setter
    public get visible(): boolean {
        return this.model ?? this.visibility;
    }
    public set visible(value: boolean) {
        this.visibility = value;
        this.$emit('visibilityChanged', value);
    }

    public get containerNames(): string[] {
        return this.brandBookmarkContainers.list.map(i => i.name);
    }

    // Watcher:

    // Lifecycle Methods:

    // Methods:
    public async save() {
        if (!((this.$refs.form as Vue & { validate: () => boolean }).validate())) {
            return;
        }

        this.brandBookmarkContainer.object = this.brandBookmarkContainers.list.find(i => i.name === this.containerName) ?? null;

        if(!this.brandBookmarkContainer.object?.id) {
            // Create new container with one bookmark:
            await this.brandBookmarkContainer.create(async () => {
                if(!this.containerName) {
                    return null;
                }
                return await BrandBookmarkContainersService.upsertBrandBookmarkContainerCurrentUserDefaultAccount({
                    name: this.containerName,
                    brandBookmarks: [{ id: this.brandId }]
                });
            });
        }
        else {
            // Add bookmark to existing container:
            await this.brandBookmark.create(async () => {
                if(!this.brandBookmarkContainer.object?.id) {
                    return null;
                }
                return await BrandBookmarksService.addBrandBookmark(this.brandBookmarkContainer.object.id, { id: this.brandId });
            });
        }
        
        this.close();
    }

    public reset(): void {
        this.containerName = this.$i18n.t('saveBrandBookmarkPopup.defaultContainerName') as string;
        this.refreshBrandBookmarkContainers();
    }

    public async refreshBrandBookmarkContainers(): Promise<void> {
        await this.brandBookmarkContainers.load(async () => {
            return await BrandBookmarkContainersService.getBrandBookmarkContainersCurrentUserDefaultAccount();
        });
    }

    public close(): void {
        this.visible = false;
    }
}
