var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "" } },
    [
      _c(
        "v-flex",
        {
          class: {
            container: true,
            "overflow-y-hidden": true,
            blur: _vm.expanderVisible && !_vm.expanded,
            "pa-0": true,
          },
          style: {
            "max-height": _vm.expanded
              ? _vm.contentHeight + "px"
              : _vm.heightLimit + "px",
          },
          attrs: { grow: "" },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "resize",
                  rawName: "v-resize",
                  value: _vm.onContentResize,
                  expression: "onContentResize",
                },
              ],
              ref: "content",
            },
            [_vm._t("default")],
            2
          ),
        ]
      ),
      _vm.expanderVisible
        ? _c(
            "v-flex",
            { staticClass: "text-center", attrs: { shrink: "" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { plain: "", color: "tertiary" },
                  on: { click: _vm.toggleExpansion },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.expanded
                          ? _vm.$t("showAllExpander.collapse")
                          : _vm.$t("showAllExpander.expand")
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }