var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Popup", {
    attrs: {
      title: _vm.$t("saveBrandBookmarkPopup.title"),
      "max-width": "700",
    },
    on: { opened: _vm.reset },
    scopedSlots: _vm._u(
      [
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [_vm._t("activator", null, null, { on: on, attrs: attrs })]
          },
        },
        {
          key: "default",
          fn: function () {
            return [
              _c(
                "v-card-text",
                { staticClass: "pt-2 pb-0" },
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      attrs: { light: "" },
                      model: {
                        value: _vm.valid,
                        callback: function ($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid",
                      },
                    },
                    [
                      _c(
                        "v-container",
                        { attrs: { fluid: "" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("v-combobox", {
                                    attrs: {
                                      loading:
                                        _vm.brandBookmarkContainers.isBusy,
                                      "search-input": _vm.containerName,
                                      items: _vm.containerNames,
                                      label: _vm.$t(
                                        "saveBrandBookmarkPopup.containerName"
                                      ),
                                      "hide-no-data": "",
                                      hint: _vm.$t(
                                        "saveBrandBookmarkPopup.containerNameHint"
                                      ),
                                      required: "",
                                      rules: [_vm.$validation.required],
                                    },
                                    on: {
                                      "update:searchInput": function ($event) {
                                        _vm.containerName = $event
                                      },
                                      "update:search-input": function ($event) {
                                        _vm.containerName = $event
                                      },
                                    },
                                    model: {
                                      value: _vm.containerName,
                                      callback: function ($$v) {
                                        _vm.containerName = $$v
                                      },
                                      expression: "containerName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled:
                          !_vm.valid || _vm.brandBookmarkContainers.isBusy,
                        plain: "",
                        loading:
                          _vm.brandBookmarkContainer.isBusy ||
                          _vm.brandBookmark.isBusy,
                      },
                      on: { click: _vm.save },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("saveBrandBookmarkPopup.saveButton"))
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v
      },
      expression: "visible",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }