var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-column fill-height" },
    [
      _c(
        "div",
        {
          class: {
            blur: _vm.enabled,
            "d-flex": true,
            "flex-column": true,
            "fill-height": true,
          },
        },
        [_vm._t("default")],
        2
      ),
      _vm.enabled
        ? _c(
            "v-overlay",
            {
              attrs: {
                absolute: "",
                opacity: "0.00",
                dark: false,
                light: true,
                "z-index": "0",
              },
            },
            [
              !_vm.userAccount
                ? [
                    _c(
                      "v-container",
                      { attrs: { fluid: "" } },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { align: "center", cols: "12" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { plain: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$auth.signIn()
                                      },
                                    },
                                  },
                                  [
                                    _c("v-icon", { attrs: { left: "" } }, [
                                      _vm._v("fa-light fa-right-to-bracket"),
                                    ]),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("brand.tile.loginMessage")
                                        ) +
                                        " "
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }