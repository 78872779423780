var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      class: _vm.$attrs.class,
      attrs: {
        light: "",
        width: _vm.width,
        "max-width": _vm.maxWidth,
        elevation: "3",
        rounded: "",
      },
    },
    [
      _vm.brandStateCategoryColor
        ? _c(
            "v-tooltip",
            {
              attrs: { bottom: "", color: _vm.brandStateCategoryColor },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function (ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-system-bar",
                          _vm._g(
                            _vm._b(
                              {
                                attrs: {
                                  color: _vm.brandStateCategoryColor,
                                  height: "6",
                                },
                              },
                              "v-system-bar",
                              attrs,
                              false
                            ),
                            on
                          )
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                3019154328
              ),
            },
            [
              _vm.brandStateCategoryIcon
                ? _c("v-icon", { attrs: { dark: "", left: "" } }, [
                    _vm._v(_vm._s(_vm.brandStateCategoryIcon)),
                  ])
                : _vm._e(),
              _vm.brandStateCategory
                ? [
                    _vm.brandStateCategory.shortDescription
                      ? _c("span", [
                          _vm._v(
                            _vm._s(_vm.brandStateCategory.shortDescription)
                          ),
                        ])
                      : _vm.brandStateCategory.key
                      ? _c("span", [_vm._v(_vm._s(_vm.brandStateCategory.key))])
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _c(
        "HiddenBrandOverlay",
        { attrs: { enabled: !_vm.loading && _vm.hidden } },
        [
          _vm.loading
            ? [
                _c("v-skeleton-loader", {
                  attrs: {
                    type: "list-item-avatar-two-line, image, list-item-two-line@3, divider, list-item-two-line, list-item-three-line, divider, list-item-avatar",
                  },
                }),
              ]
            : [
                _c(
                  "v-card-title",
                  { staticStyle: { display: "block" } },
                  [
                    _c(
                      "v-list-item",
                      { attrs: { "two-line": "" } },
                      [
                        _c(
                          "v-list-item-avatar",
                          { attrs: { tile: "" } },
                          [
                            _vm.brandOrDummy.registrationOfficeCode &&
                            _vm.brandOrDummy.registrationOfficeCode.description
                              ? _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function (ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _vm.brandOrDummy
                                                .registrationOfficeCode &&
                                              _vm.brandOrDummy
                                                .registrationOfficeCode.key
                                                ? _c(
                                                    "v-img",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            src: _vm.$braendz.registrationOfficeCodeFlag(
                                                              _vm.brandOrDummy
                                                                .registrationOfficeCode
                                                                .key
                                                            ),
                                                            contain: "",
                                                            "max-height": "50",
                                                            "max-width": "50",
                                                          },
                                                        },
                                                        "v-img",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    )
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      889679034
                                    ),
                                  },
                                  [
                                    _vm.brandOrDummy.registrationOfficeCode
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.brandOrDummy
                                                .registrationOfficeCode
                                                .description
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              : _vm.brandOrDummy.registrationOfficeCode &&
                                _vm.brandOrDummy.registrationOfficeCode.key
                              ? _c("v-img", {
                                  attrs: {
                                    src: _vm.$braendz.registrationOfficeCodeFlag(
                                      _vm.brandOrDummy.registrationOfficeCode
                                        .key
                                    ),
                                    contain: "",
                                    "max-height": "50",
                                    "max-width": "50",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [
                            !_vm.brandOrDummy.brandType ||
                            _vm.brandOrDummy.brandType.key !== "Figurative"
                              ? _c(
                                  "TruncateTooltip",
                                  {
                                    attrs: {
                                      label: _vm.brandOrDummy.name,
                                      top: "",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list-item-title",
                                      { staticClass: "text-h5 text-truncate" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.brandOrDummy.name) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "v-list-item-title",
                                  [_c("v-icon", [_vm._v("$no-text")])],
                                  1
                                ),
                            _vm.brandOrDummy.brandType &&
                            _vm.brandOrDummy.brandType.description
                              ? _c("v-list-item-subtitle", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.brandOrDummy.brandType.description
                                      ) +
                                      " "
                                  ),
                                ])
                              : _vm.brandOrDummy.brandType
                              ? _c("v-list-item-subtitle", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.brandOrDummy.brandType.key) +
                                      " "
                                  ),
                                ])
                              : _c("v-list-item-subtitle", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("brand.tile.valueNotAvailable")
                                      ) +
                                      " "
                                  ),
                                ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("v-divider", { staticClass: "mx-3" }),
                _c(
                  "v-card-text",
                  [
                    _c(
                      "v-row",
                      {
                        staticStyle: { height: "150px" },
                        attrs: { align: "center" },
                      },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "d-flex justify-center",
                            attrs: { cols: "12" },
                          },
                          [
                            _vm.fullBrandLogoUrl
                              ? _c("v-img", {
                                  attrs: {
                                    src: _vm.fullBrandLogoUrl,
                                    contain: "",
                                    "max-height": "100",
                                  },
                                })
                              : _c("v-icon", { attrs: { size: "100" } }, [
                                  _vm._v("fa-light fa-image-slash"),
                                ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("v-divider", { staticClass: "mb-2 mx-3" }),
                _c(
                  "v-card-text",
                  [
                    _vm.infoMode === "Maximum"
                      ? _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "6" } },
                              [
                                _vm.brandOrDummy.brandState &&
                                _vm.brandOrDummy.brandState.description
                                  ? _c(
                                      "TruncateTooltip",
                                      {
                                        attrs: {
                                          label:
                                            _vm.brandOrDummy.brandState
                                              .description,
                                          top: "",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "d-block text-truncate body-1",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.brandOrDummy.brandState
                                                  .description
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm.brandOrDummy.brandState
                                  ? _c(
                                      "TruncateTooltip",
                                      {
                                        attrs: {
                                          label:
                                            _vm.brandOrDummy.brandState.key,
                                          top: "",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "d-block text-truncate body-1",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.brandOrDummy.brandState.key
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass:
                                          "d-block text-truncate body-1 text--disabled",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "brand.tile.valueNotAvailable"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                _c("span", { staticClass: "caption d-block" }, [
                                  _vm._v(_vm._s(_vm.$t("brand.tile.state"))),
                                ]),
                              ],
                              1
                            ),
                            _vm.brandOrDummy.registrationNumber
                              ? _c("v-col", { attrs: { cols: "6" } }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-block text-truncate body-1",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.brandOrDummy.registrationNumber
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "caption d-block" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "brand.tile.registrationNumber"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm.brandOrDummy.applicationNumber
                              ? _c("v-col", { attrs: { cols: "6" } }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-block text-truncate body-1",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.brandOrDummy.applicationNumber
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "caption d-block" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("brand.tile.applicationNumber")
                                        )
                                      ),
                                    ]
                                  ),
                                ])
                              : _c("v-col", { attrs: { cols: "6" } }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-block text-truncate body-1 text--disabled",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("brand.tile.valueNotAvailable")
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "caption d-block" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "brand.tile.registrationNumber"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.infoMode === "Maximum"
                      ? _c(
                          "v-row",
                          [
                            _c("v-col", { attrs: { cols: "6" } }, [
                              _vm.brandOrDummy.applicationDate
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-block text-truncate body-1",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$d(
                                            new Date(
                                              _vm.brandOrDummy.applicationDate
                                            ),
                                            "short"
                                          )
                                        )
                                      ),
                                    ]
                                  )
                                : _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-block text-truncate body-1 text--disabled",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("brand.tile.valueNotAvailable")
                                        )
                                      ),
                                    ]
                                  ),
                              _c("span", { staticClass: "caption d-block" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("brand.tile.applicationDate"))
                                ),
                              ]),
                            ]),
                            _c("v-col", { attrs: { cols: "6" } }, [
                              _vm.brandOrDummy.registrationDate
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-block text-truncate body-1",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$d(
                                            new Date(
                                              _vm.brandOrDummy.registrationDate
                                            ),
                                            "short"
                                          )
                                        )
                                      ),
                                    ]
                                  )
                                : _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-block text-truncate body-1 text--disabled",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("brand.tile.valueNotAvailable")
                                        )
                                      ),
                                    ]
                                  ),
                              _c("span", { staticClass: "caption d-block" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("brand.tile.registrationDate"))
                                ),
                              ]),
                            ]),
                            _c("v-col", { attrs: { cols: "6" } }, [
                              _vm.brandOrDummy.expiryDate
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-block text-truncate body-1",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$d(
                                            new Date(
                                              _vm.brandOrDummy.expiryDate
                                            ),
                                            "short"
                                          )
                                        )
                                      ),
                                    ]
                                  )
                                : _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-block text-truncate body-1 text--disabled",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("brand.tile.valueNotAvailable")
                                        )
                                      ),
                                    ]
                                  ),
                              _c("span", { staticClass: "caption d-block" }, [
                                _vm._v(_vm._s(_vm.$t("brand.tile.expiryDate"))),
                              ]),
                            ]),
                            _c("v-col", { attrs: { cols: "6" } }, [
                              _vm.brandOrDummy.oppositionPeriodEndDate
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-block text-truncate body-1",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$d(
                                            new Date(
                                              _vm.brandOrDummy.oppositionPeriodEndDate
                                            ),
                                            "short"
                                          )
                                        )
                                      ),
                                    ]
                                  )
                                : _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-block text-truncate body-1 text--disabled",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("brand.tile.valueNotAvailable")
                                        )
                                      ),
                                    ]
                                  ),
                              _c("span", { staticClass: "caption d-block" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("brand.tile.oppositionPeriodEndDate")
                                  )
                                ),
                              ]),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-row",
                      [
                        !_vm.userAccount
                          ? _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { plain: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$auth.signIn()
                                      },
                                    },
                                  },
                                  [
                                    _c("v-icon", { attrs: { left: "" } }, [
                                      _vm._v("fa-light fa-right-to-bracket"),
                                    ]),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("brand.tile.loginMessage")
                                        ) +
                                        " "
                                    ),
                                  ],
                                  1
                                ),
                                _c("span", { staticClass: "caption d-block" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("brand.tile.ownerAddress"))
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm.brandOrDummy.ownerAddress
                          ? _c("v-col", { attrs: { cols: "12" } }, [
                              _c("span", { staticClass: "body-1" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$braendz.getAddressLine(
                                      _vm.brandOrDummy.ownerAddress
                                    )
                                  )
                                ),
                              ]),
                              _c("span", { staticClass: "caption d-block" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("brand.tile.ownerAddress"))
                                ),
                              ]),
                            ])
                          : _c("v-col", { attrs: { cols: "12" } }, [
                              _c(
                                "span",
                                { staticClass: "body-1 text--disabled" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("brand.tile.valueNotAvailable")
                                    )
                                  ),
                                ]
                              ),
                              _c("span", { staticClass: "d-block caption" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("brand.tile.ownerAddress"))
                                ),
                              ]),
                            ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.infoMode === "Maximum"
                  ? _c("v-divider", { staticClass: "my-2 mx-3" })
                  : _vm._e(),
                _c(
                  "v-card-text",
                  { staticClass: "fill-height" },
                  [
                    !_vm.showGoodsServices
                      ? _c(
                          "v-row",
                          [
                            _vm.brandOrDummy.niceClasses &&
                            _vm.brandOrDummy.niceClasses.length > 0
                              ? _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "caption d-block" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("brand.tile.niceClasses")
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._l(
                                      _vm.brandOrDummy.niceClasses,
                                      function (niceClass, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "d-inline",
                                          },
                                          [
                                            niceClass.description
                                              ? _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { bottom: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                "v-chip",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "mr-1 mb-1",
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                        outlined:
                                                                          "",
                                                                      },
                                                                    },
                                                                    "v-chip",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "body-1",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          niceClass.key
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          niceClass.description
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              : _c(
                                                  "v-chip",
                                                  {
                                                    staticClass: "mr-1 mb-1",
                                                    attrs: {
                                                      small: "",
                                                      outlined: "",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "body-1" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(niceClass.key)
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : _c("v-col", { attrs: { cols: "12" } }, [
                                  _c(
                                    "span",
                                    { staticClass: "caption d-block" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("brand.tile.niceClasses"))
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-block text-truncate body-1 text--disabled",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("brand.tile.valueNotAvailable")
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                          ],
                          1
                        )
                      : _vm.brandOrDummy.goodsServices
                      ? _c(
                          "v-row",
                          { staticClass: "fill-height" },
                          [
                            _c(
                              "show-all-expander",
                              { attrs: { "height-limit": "400" } },
                              [
                                _c(
                                  "v-list",
                                  { attrs: { color: "transparent" } },
                                  _vm._l(
                                    _vm.brandOrDummy.goodsServices,
                                    function (goodOrService, index) {
                                      return _c(
                                        "v-list-item",
                                        { key: index },
                                        [
                                          goodOrService.niceClass
                                            ? _c(
                                                "v-list-item-avatar",
                                                {
                                                  staticClass:
                                                    "align-self-start",
                                                },
                                                [
                                                  goodOrService.niceClass
                                                    .description
                                                    ? _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { bottom: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function (
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  var attrs =
                                                                    ref.attrs
                                                                  return [
                                                                    goodOrService.niceClass
                                                                      ? _c(
                                                                          "v-chip",
                                                                          _vm._g(
                                                                            _vm._b(
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    small:
                                                                                      "",
                                                                                    outlined:
                                                                                      "",
                                                                                  },
                                                                              },
                                                                              "v-chip",
                                                                              attrs,
                                                                              false
                                                                            ),
                                                                            on
                                                                          ),
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "body-1",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    goodOrService
                                                                                      .niceClass
                                                                                      .key
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                goodOrService
                                                                  .niceClass
                                                                  .description
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    : _c(
                                                        "v-chip",
                                                        {
                                                          attrs: {
                                                            small: "",
                                                            outlined: "",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "body-1",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  goodOrService
                                                                    .niceClass
                                                                    .key
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              goodOrService.description
                                                ? _c(
                                                    "v-list-item-title",
                                                    {
                                                      staticClass: "wrap-text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          goodOrService.description
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _c(
                                                    "v-list-item-title",
                                                    {
                                                      staticClass:
                                                        "font-italic text--disabled",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "brand.tile.valueNotAvailable"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.infoMode === "Maximum"
                      ? _c(
                          "v-row",
                          [
                            _vm.brandOrDummy.viennaClasses &&
                            _vm.brandOrDummy.viennaClasses.length > 0
                              ? _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "caption d-block" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("brand.tile.viennaClasses")
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._l(
                                      _vm.brandOrDummy.viennaClasses,
                                      function (viennaClass, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "d-inline",
                                          },
                                          [
                                            viennaClass.description
                                              ? _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { bottom: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                "v-chip",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "mr-1 mb-1",
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                        outlined:
                                                                          "",
                                                                      },
                                                                    },
                                                                    "v-chip",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "body-1",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          viennaClass.key
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          viennaClass.description
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              : _c(
                                                  "v-chip",
                                                  {
                                                    staticClass: "mr-1 mb-1",
                                                    attrs: {
                                                      small: "",
                                                      outlined: "",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "body-1" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            viennaClass.key
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : _c("v-col", { attrs: { cols: "12" } }, [
                                  _c(
                                    "span",
                                    { staticClass: "caption d-block" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("brand.tile.viennaClasses")
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-block text-truncate body-1 text--disabled",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("brand.tile.valueNotAvailable")
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c("v-spacer"),
                _c("v-divider", { staticClass: "mt-2 mx-3" }),
                _c(
                  "v-card-actions",
                  [
                    _vm.brand
                      ? _c("BrandDetailsPopup", {
                          attrs: { id: _vm.brandId },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { text: "" } },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", { attrs: { left: "" } }, [
                                          _vm._v(
                                            "fa-light fa-memo-circle-info"
                                          ),
                                        ]),
                                        _vm._v(" Details "),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3016226215
                          ),
                        })
                      : _vm._e(),
                    _c("v-spacer"),
                    _c(
                      "v-speed-dial",
                      {
                        attrs: { direction: "top", "open-on-hover": "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function () {
                              return [
                                _c(
                                  "v-btn",
                                  { attrs: { icon: "" } },
                                  [
                                    _c("v-icon", [
                                      _vm._v(
                                        "fa-light fa-ellipsis-stroke-vertical"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        !_vm.brandBookmarkContainerId
                          ? _c(
                              "v-tooltip",
                              {
                                attrs: { left: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    fab: "",
                                                    disabled: !_vm.userAccount,
                                                  },
                                                  on: {
                                                    click: _vm.bookmarkBrand,
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: "text-light",
                                                  },
                                                },
                                                [_vm._v("fa-solid fa-star")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2440962853
                                ),
                              },
                              [_c("span", [_vm._v("Bookmark this brand")])]
                            )
                          : _c(
                              "v-tooltip",
                              {
                                attrs: { left: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  fab: "",
                                                  disabled: !_vm.userAccount,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.deleteBookmarkConfirmationPopupVisible = true
                                                  },
                                                },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: { color: "text-light" },
                                              },
                                              [_vm._v("fa-light fa-star")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [_c("span", [_vm._v("Remove bookmark")])]
                            ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { left: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              fab: "",
                                              disabled: !_vm.userAccount,
                                            },
                                            on: { click: _vm.toggleExclusion },
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _vm.isExcluded
                                          ? _c(
                                              "v-icon",
                                              { attrs: { color: "primary" } },
                                              [_vm._v("fa-light fa-eye")]
                                            )
                                          : _c(
                                              "v-icon",
                                              {
                                                attrs: { color: "text-light" },
                                              },
                                              [_vm._v("fa-light fa-eye-slash")]
                                            ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _vm.isExcluded
                              ? _c("span", [_vm._v("Include this brand")])
                              : _c("span", [_vm._v("Exclude this brand")]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm.brandId
                      ? _c("SaveBrandBookmarkPopup", {
                          attrs: { brandId: _vm.brandId },
                          model: {
                            value: _vm.saveBookmarkPopupVisible,
                            callback: function ($$v) {
                              _vm.saveBookmarkPopupVisible = $$v
                            },
                            expression: "saveBookmarkPopupVisible",
                          },
                        })
                      : _vm._e(),
                    _c("ConfirmationPopup", {
                      attrs: {
                        yes: "",
                        cancel: "",
                        question: "",
                        title: "Entfernen",
                        text: "Möchten Sie diese Marke aus dieser Liste entfernen?",
                      },
                      on: { yes: _vm.removeBookmark },
                      model: {
                        value: _vm.deleteBookmarkConfirmationPopupVisible,
                        callback: function ($$v) {
                          _vm.deleteBookmarkConfirmationPopupVisible = $$v
                        },
                        expression: "deleteBookmarkConfirmationPopupVisible",
                      },
                    }),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }