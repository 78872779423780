var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-dialog", {
    attrs: { scrollable: "", "max-width": "800" },
    scopedSlots: _vm._u(
      [
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [_vm._t("activator", null, null, { on: on, attrs: attrs })]
          },
        },
        {
          key: "default",
          fn: function (dialog) {
            return [
              _c(
                "v-card",
                { staticClass: "mx-auto" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dark: "", dense: "" } },
                    [
                      _c("v-toolbar-title", [_vm._v("Save Bookmark")]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", dark: "" },
                          on: {
                            click: function ($event) {
                              dialog.value = false
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("fa-light fa-xmark")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "pt-6 pb-0" },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          attrs: { light: "" },
                          model: {
                            value: _vm.valid,
                            callback: function ($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid",
                          },
                        },
                        [
                          _c(
                            "v-container",
                            { attrs: { fluid: "" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-combobox", {
                                        attrs: {
                                          "search-input": _vm.containerName,
                                          items: _vm.containerNames,
                                          label: "Watch List",
                                          "hide-no-data": "",
                                          hint: "Select an existing list or type in a new name.",
                                          required: "",
                                          rules: [_vm.$validation.required],
                                        },
                                        on: {
                                          "update:searchInput": function (
                                            $event
                                          ) {
                                            _vm.containerName = $event
                                          },
                                          "update:search-input": function (
                                            $event
                                          ) {
                                            _vm.containerName = $event
                                          },
                                        },
                                        model: {
                                          value: _vm.containerName,
                                          callback: function ($$v) {
                                            _vm.containerName = $$v
                                          },
                                          expression: "containerName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { disabled: !_vm.valid, text: "" },
                          on: { click: _vm.save },
                        },
                        [_vm._v("Save")]
                      ),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v
      },
      expression: "visible",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }