var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    _vm._b(
      {
        staticClass: "d-flex flex-column",
        attrs: {
          light: "",
          width: _vm.width,
          height: _vm.height,
          "max-width": _vm.maxWidth,
          "max-height": _vm.maxHeight,
          elevation: "3",
          rounded: "",
        },
      },
      "v-card",
      _vm.$attrs,
      false
    ),
    [
      _vm.brandStateCategoryColor
        ? _c(
            "v-tooltip",
            {
              attrs: { bottom: "", color: _vm.brandStateCategoryColor },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function (ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-system-bar",
                          _vm._g(
                            _vm._b(
                              {
                                attrs: {
                                  color: _vm.brandStateCategoryColor,
                                  height: "6",
                                },
                              },
                              "v-system-bar",
                              attrs,
                              false
                            ),
                            on
                          )
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                3019154328
              ),
            },
            [
              _vm.brandStateCategoryIcon
                ? _c("v-icon", { attrs: { dark: "", left: "" } }, [
                    _vm._v(_vm._s(_vm.brandStateCategoryIcon)),
                  ])
                : _vm._e(),
              _vm.brandStateCategory
                ? [
                    _vm.brandStateCategory.shortDescription
                      ? _c("span", [
                          _vm._v(
                            _vm._s(_vm.brandStateCategory.shortDescription)
                          ),
                        ])
                      : _vm.brandStateCategory.key
                      ? _c("span", [_vm._v(_vm._s(_vm.brandStateCategory.key))])
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm.loading
        ? [
            _c("v-skeleton-loader", {
              attrs: {
                type: "list-item-avatar-two-line, image, list-item-two-line@3, divider, list-item-two-line, list-item-three-line, divider, list-item-avatar",
              },
            }),
          ]
        : [
            _c(
              "v-card-title",
              { staticStyle: { display: "block" } },
              [
                _c(
                  "v-list-item",
                  { attrs: { "two-line": "" } },
                  [
                    _c(
                      "v-list-item-avatar",
                      { attrs: { tile: "" } },
                      [
                        _vm.brandData.registrationOfficeCode &&
                        _vm.brandData.registrationOfficeCode.description
                          ? _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _vm.brandData
                                            .registrationOfficeCode &&
                                          _vm.brandData.registrationOfficeCode
                                            .key
                                            ? _c(
                                                "v-img",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        src: _vm.$braendz.registrationOfficeCodeFlag(
                                                          _vm.brandData
                                                            .registrationOfficeCode
                                                            .key
                                                        ),
                                                        contain: "",
                                                        "max-height": "50",
                                                        "max-width": "50",
                                                      },
                                                    },
                                                    "v-img",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                )
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2105552255
                                ),
                              },
                              [
                                _vm.brandData.registrationOfficeCode
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.brandData.registrationOfficeCode
                                            .description
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            )
                          : _vm.brandData.registrationOfficeCode &&
                            _vm.brandData.registrationOfficeCode.key
                          ? _c("v-img", {
                              attrs: {
                                src: _vm.$braendz.registrationOfficeCodeFlag(
                                  _vm.brandData.registrationOfficeCode.key
                                ),
                                contain: "",
                                "max-height": "50",
                                "max-width": "50",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item-content",
                      [
                        !_vm.brandData.brandType ||
                        _vm.brandData.brandType.key !== "Figurative"
                          ? _c(
                              "TruncateTooltip",
                              { attrs: { label: _vm.brandData.name, top: "" } },
                              [
                                _c(
                                  "v-list-item-title",
                                  { staticClass: "text-h5 text-truncate" },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.brandData.name) + " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _c(
                              "v-list-item-title",
                              [_c("v-icon", [_vm._v("$no-text")])],
                              1
                            ),
                        _vm.brandData.brandType &&
                        _vm.brandData.brandType.description
                          ? _c("v-list-item-subtitle", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.brandData.brandType.description) +
                                  " "
                              ),
                            ])
                          : _vm.brandData.brandType
                          ? _c("v-list-item-subtitle", [
                              _vm._v(
                                " " + _vm._s(_vm.brandData.brandType.key) + " "
                              ),
                            ])
                          : _c("v-list-item-subtitle", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("brand.tile.valueNotAvailable")
                                  ) +
                                  " "
                              ),
                            ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("v-divider", { staticClass: "mx-3" }),
            _c(
              "v-card-text",
              { staticClass: "d-flex justify-center align-center" },
              [
                _vm.fullBrandLogoUrl
                  ? _c("v-img", {
                      attrs: {
                        src: _vm.fullBrandLogoUrl,
                        contain: "",
                        "max-height": "100",
                        "max-width": "65%",
                      },
                    })
                  : _c("v-icon", { attrs: { size: "100" } }, [
                      _vm._v("fa-light fa-image-slash"),
                    ]),
              ],
              1
            ),
            _c("v-divider", { staticClass: "mx-3" }),
            _vm.showCommon || _vm.showDates || _vm.showOwner
              ? _c(
                  "v-card-text",
                  [
                    _vm.showCommon
                      ? _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "6" } },
                              [
                                _vm.brandData.brandState &&
                                _vm.brandData.brandState.description
                                  ? _c(
                                      "TruncateTooltip",
                                      {
                                        attrs: {
                                          label:
                                            _vm.brandData.brandState
                                              .description,
                                          top: "",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "d-block text-truncate body-1",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.brandData.brandState
                                                  .description
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm.brandData.brandState
                                  ? _c(
                                      "TruncateTooltip",
                                      {
                                        attrs: {
                                          label: _vm.brandData.brandState.key,
                                          top: "",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "d-block text-truncate body-1",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.brandData.brandState.key
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass:
                                          "d-block text-truncate body-1 text--disabled",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "brand.tile.valueNotAvailable"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                _c("span", { staticClass: "caption d-block" }, [
                                  _vm._v(_vm._s(_vm.$t("brand.tile.state"))),
                                ]),
                              ],
                              1
                            ),
                            _vm.brandData.registrationNumber
                              ? _c("v-col", { attrs: { cols: "6" } }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-block text-truncate body-1",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.brandData.registrationNumber)
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "caption d-block" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "brand.tile.registrationNumber"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm.brandData.applicationNumber
                              ? _c("v-col", { attrs: { cols: "6" } }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-block text-truncate body-1",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.brandData.applicationNumber)
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "caption d-block" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("brand.tile.applicationNumber")
                                        )
                                      ),
                                    ]
                                  ),
                                ])
                              : _c("v-col", { attrs: { cols: "6" } }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-block text-truncate body-1 text--disabled",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("brand.tile.valueNotAvailable")
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "caption d-block" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "brand.tile.registrationNumber"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.showDates
                      ? _c(
                          "v-row",
                          [
                            _c("v-col", { attrs: { cols: "6" } }, [
                              _vm.brandData.applicationDate
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-block text-truncate body-1",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$d(
                                            new Date(
                                              _vm.brandData.applicationDate
                                            ),
                                            "short"
                                          )
                                        )
                                      ),
                                    ]
                                  )
                                : _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-block text-truncate body-1 text--disabled",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("brand.tile.valueNotAvailable")
                                        )
                                      ),
                                    ]
                                  ),
                              _c("span", { staticClass: "caption d-block" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("brand.tile.applicationDate"))
                                ),
                              ]),
                            ]),
                            _c("v-col", { attrs: { cols: "6" } }, [
                              _vm.brandData.registrationDate
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-block text-truncate body-1",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$d(
                                            new Date(
                                              _vm.brandData.registrationDate
                                            ),
                                            "short"
                                          )
                                        )
                                      ),
                                    ]
                                  )
                                : _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-block text-truncate body-1 text--disabled",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("brand.tile.valueNotAvailable")
                                        )
                                      ),
                                    ]
                                  ),
                              _c("span", { staticClass: "caption d-block" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("brand.tile.registrationDate"))
                                ),
                              ]),
                            ]),
                            _c("v-col", { attrs: { cols: "6" } }, [
                              _vm.brandData.expiryDate
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-block text-truncate body-1",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$d(
                                            new Date(_vm.brandData.expiryDate),
                                            "short"
                                          )
                                        )
                                      ),
                                    ]
                                  )
                                : _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-block text-truncate body-1 text--disabled",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("brand.tile.valueNotAvailable")
                                        )
                                      ),
                                    ]
                                  ),
                              _c("span", { staticClass: "caption d-block" }, [
                                _vm._v(_vm._s(_vm.$t("brand.tile.expiryDate"))),
                              ]),
                            ]),
                            _c("v-col", { attrs: { cols: "6" } }, [
                              _vm.brandData.oppositionPeriodEndDate
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-block text-truncate body-1",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$d(
                                            new Date(
                                              _vm.brandData.oppositionPeriodEndDate
                                            ),
                                            "short"
                                          )
                                        )
                                      ),
                                    ]
                                  )
                                : _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-block text-truncate body-1 text--disabled",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("brand.tile.valueNotAvailable")
                                        )
                                      ),
                                    ]
                                  ),
                              _c("span", { staticClass: "caption d-block" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("brand.tile.oppositionPeriodEndDate")
                                  )
                                ),
                              ]),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.showOwner
                      ? _c(
                          "v-row",
                          [
                            !_vm.userAccount
                              ? _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { plain: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.$auth.signIn()
                                          },
                                        },
                                      },
                                      [
                                        _c("v-icon", { attrs: { left: "" } }, [
                                          _vm._v(
                                            "fa-light fa-right-to-bracket"
                                          ),
                                        ]),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("brand.tile.loginMessage")
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "caption d-block" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("brand.tile.ownerAddress")
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm.brandData.ownerAddress
                              ? _c("v-col", { attrs: { cols: "12" } }, [
                                  _c("span", { staticClass: "body-1" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$braendz.getAddressLine(
                                          _vm.brandData.ownerAddress
                                        )
                                      )
                                    ),
                                  ]),
                                  _c(
                                    "span",
                                    { staticClass: "caption d-block" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("brand.tile.ownerAddress")
                                        )
                                      ),
                                    ]
                                  ),
                                ])
                              : _c("v-col", { attrs: { cols: "12" } }, [
                                  _c(
                                    "span",
                                    { staticClass: "body-1 text--disabled" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("brand.tile.valueNotAvailable")
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "d-block caption" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("brand.tile.ownerAddress")
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.showCommon || _vm.showDates || _vm.showOwner
              ? _c("v-divider", { staticClass: "mx-3" })
              : _vm._e(),
            _vm.showNiceClasses ||
            _vm.showGoodsServices ||
            _vm.showViennaClasses
              ? _c(
                  "v-card-text",
                  { staticClass: "flex-grow-1 overflow-y-auto scrollbar" },
                  [
                    _vm.showNiceClasses && !_vm.showGoodsServices
                      ? _c(
                          "v-row",
                          [
                            _vm.brandData.niceClasses &&
                            _vm.brandData.niceClasses.length > 0
                              ? _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "caption d-block" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("brand.tile.niceClasses")
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._l(
                                      _vm.brandData.niceClasses,
                                      function (niceClass, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "d-inline",
                                          },
                                          [
                                            niceClass.description
                                              ? _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { bottom: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                "v-chip",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "mr-1 mb-1",
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                        outlined:
                                                                          "",
                                                                      },
                                                                    },
                                                                    "v-chip",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "body-1",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          niceClass.key
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          niceClass.description
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              : _c(
                                                  "v-chip",
                                                  {
                                                    staticClass: "mr-1 mb-1",
                                                    attrs: {
                                                      small: "",
                                                      outlined: "",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "body-1" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(niceClass.key)
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : _c("v-col", { attrs: { cols: "12" } }, [
                                  _c(
                                    "span",
                                    { staticClass: "caption d-block" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("brand.tile.niceClasses"))
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-block text-truncate body-1 text--disabled",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("brand.tile.valueNotAvailable")
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.showGoodsServices
                      ? _c(
                          "v-row",
                          [
                            _vm.brandData.goodsServices &&
                            _vm.brandData.goodsServices.length > 0
                              ? _c(
                                  "v-col",
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "caption d-block" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("brand.tile.goodsServices")
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-list",
                                      { attrs: { color: "transparent" } },
                                      _vm._l(
                                        _vm.brandData.goodsServices,
                                        function (goodOrService, index) {
                                          return _c(
                                            "v-list-item",
                                            { key: index },
                                            [
                                              goodOrService.niceClass
                                                ? _c(
                                                    "v-list-item-avatar",
                                                    {
                                                      staticClass:
                                                        "align-self-start",
                                                    },
                                                    [
                                                      goodOrService.niceClass
                                                        .description
                                                        ? _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                bottom: "",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "activator",
                                                                      fn: function (
                                                                        ref
                                                                      ) {
                                                                        var on =
                                                                          ref.on
                                                                        var attrs =
                                                                          ref.attrs
                                                                        return [
                                                                          goodOrService.niceClass
                                                                            ? _c(
                                                                                "v-chip",
                                                                                _vm._g(
                                                                                  _vm._b(
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          small:
                                                                                            "",
                                                                                          outlined:
                                                                                            "",
                                                                                        },
                                                                                    },
                                                                                    "v-chip",
                                                                                    attrs,
                                                                                    false
                                                                                  ),
                                                                                  on
                                                                                ),
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "body-1",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          goodOrService
                                                                                            .niceClass
                                                                                            .key
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    goodOrService
                                                                      .niceClass
                                                                      .description
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        : _c(
                                                            "v-chip",
                                                            {
                                                              attrs: {
                                                                small: "",
                                                                outlined: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "body-1",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      goodOrService
                                                                        .niceClass
                                                                        .key
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  goodOrService.description
                                                    ? _c(
                                                        "v-list-item-title",
                                                        {
                                                          staticClass:
                                                            "wrap-text",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              goodOrService.description
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : _c(
                                                        "v-list-item-title",
                                                        {
                                                          staticClass:
                                                            "font-italic text--disabled",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "brand.tile.valueNotAvailable"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _c("v-col", { attrs: { cols: "12" } }, [
                                  _c(
                                    "span",
                                    { staticClass: "caption d-block" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("brand.tile.goodsServices")
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-block text-truncate body-1 text--disabled",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("brand.tile.valueNotAvailable")
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.showViennaClasses
                      ? _c(
                          "v-row",
                          [
                            _vm.brandData.viennaClasses &&
                            _vm.brandData.viennaClasses.length > 0
                              ? _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "caption d-block" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("brand.tile.viennaClasses")
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._l(
                                      _vm.brandData.viennaClasses,
                                      function (viennaClass, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "d-inline",
                                          },
                                          [
                                            viennaClass.description
                                              ? _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { bottom: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                "v-chip",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "mr-1 mb-1",
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                        outlined:
                                                                          "",
                                                                      },
                                                                    },
                                                                    "v-chip",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "body-1",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          viennaClass.key
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          viennaClass.description
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              : _c(
                                                  "v-chip",
                                                  {
                                                    staticClass: "mr-1 mb-1",
                                                    attrs: {
                                                      small: "",
                                                      outlined: "",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "body-1" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            viennaClass.key
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : _c("v-col", { attrs: { cols: "12" } }, [
                                  _c(
                                    "span",
                                    { staticClass: "caption d-block" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("brand.tile.viennaClasses")
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-block text-truncate body-1 text--disabled",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("brand.tile.valueNotAvailable")
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _c("v-divider", { staticClass: "mx-3" }),
            _c(
              "v-card-actions",
              [
                _vm.brand
                  ? _c("BrandDetailsPopup", {
                      attrs: { id: _vm.brandId },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      { attrs: { text: "" } },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("brand.tile.detailsButton")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        4240999811
                      ),
                    })
                  : _vm._e(),
                _c("v-spacer"),
                _vm.canAddToBookmarks || _vm.canRemoveFromBookmarks
                  ? _c(
                      "v-menu",
                      {
                        attrs: {
                          rounded: "",
                          "offset-y": "",
                          top: "",
                          left: "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        { attrs: { icon: "" } },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          "fa-light fa-ellipsis-stroke-vertical"
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2334736869
                        ),
                      },
                      [
                        _c(
                          "v-list",
                          { staticClass: "pa-0", attrs: { dense: "" } },
                          [
                            _vm.canAddToBookmarks
                              ? _c(
                                  "v-list-item",
                                  {
                                    attrs: { disabled: !_vm.userAccount },
                                    on: { click: _vm.addToBookmarks },
                                  },
                                  [
                                    _c(
                                      "v-list-item-icon",
                                      { staticClass: "mr-2" },
                                      [
                                        _c("v-icon", { attrs: { small: "" } }, [
                                          _vm._v("fa-solid fa-star"),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("brand.tile.bookmarkAdd")
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.canRemoveFromBookmarks
                              ? _c(
                                  "v-list-item",
                                  {
                                    attrs: { disabled: !_vm.userAccount },
                                    on: {
                                      click: function ($event) {
                                        _vm.deleteBookmarkConfirmationPopupVisible = true
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list-item-icon",
                                      { staticClass: "mr-2" },
                                      [
                                        _c("v-icon", { attrs: { small: "" } }, [
                                          _vm._v("fa-light fa-star"),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "brand.tile.bookmarkRemove"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.brandId
                  ? _c("SaveBrandBookmarkPopup", {
                      attrs: { brandId: _vm.brandId },
                      model: {
                        value: _vm.saveBookmarkPopupVisible,
                        callback: function ($$v) {
                          _vm.saveBookmarkPopupVisible = $$v
                        },
                        expression: "saveBookmarkPopupVisible",
                      },
                    })
                  : _vm._e(),
                _c("ConfirmationPopup", {
                  attrs: {
                    yes: "",
                    cancel: "",
                    question: "",
                    title: _vm.$t(
                      "brand.tile.bookmarkRemoveConfirmation.title"
                    ),
                    text: _vm.$t("brand.tile.bookmarkRemoveConfirmation.text"),
                  },
                  on: { yes: _vm.removeFromBookmarks },
                  model: {
                    value: _vm.deleteBookmarkConfirmationPopupVisible,
                    callback: function ($$v) {
                      _vm.deleteBookmarkConfirmationPopupVisible = $$v
                    },
                    expression: "deleteBookmarkConfirmationPopupVisible",
                  },
                }),
              ],
              1
            ),
          ],
      _c("PaywallOverlay", {
        attrs: {
          hasFeature: _vm.loading || !_vm.hidden,
          requiredLogin: !_vm.userAccount,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }